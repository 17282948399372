import { BoltSlashIcon, EyeSlashIcon, KeyIcon, TrashIcon, UserGroupIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import React from 'react';
import { PRESIDENT, QUORUM_OF_THE_TWELVE } from './constants';
import { checkWasApostle } from './hooks/usePresentPositionCallDate';
import Tooltip from './Tooltip';
import { Leader } from './types';

interface Props {
  leader: Leader,
}

const Icons = ({ leader }: Props) => {
  const wasApostle = checkWasApostle(leader);
  const wasQ12 = !!leader.positions.find(position => position.type === QUORUM_OF_THE_TWELVE);
  const wasProphet = !!leader.positions.find(position => position.type === PRESIDENT);

  return (
    <>
      {leader.polygamist && <div className="mr-1">
        <Tooltip caption={`Married ${leader.polygamist} women`}>
          <UserGroupIcon className="h-4 w-4" aria-hidden="true" />
        </Tooltip>
      </div>}
      {!wasApostle && <div className="mr-1">
        <Tooltip caption="Never was an apostle">
          <EyeSlashIcon className="h-4 w-4" aria-hidden="true" />
        </Tooltip>
      </div>}
      {wasApostle && !wasQ12 && !wasProphet && <div className="mr-1">
        <Tooltip caption="Apostle but never in Q12">
          <BoltSlashIcon className="h-4 w-4" aria-hidden="true" />
        </Tooltip>
      </div>}
      {leader.presentPosition === PRESIDENT &&
        <div className="mr-1">
          <Tooltip caption="Current Prophet">
            <KeyIcon className="h-4 w-4 text-yellow-500" aria-hidden="true" />
          </Tooltip>
        </div>
      }
      {leader.presentPosition !== PRESIDENT && wasProphet &&
        <Tooltip caption="Prophet">
          <KeyIcon className="h-4 w-4" aria-hidden="true" />
        </Tooltip>
      }
      {wasApostle && leader.deathDate && !moment(leader.deathDate).isSame(leader.releaseDate) &&
        <Tooltip caption="Released early">
          <TrashIcon className="h-4 w-4" aria-hidden="true" />
        </Tooltip>
      }

      {/* For placeholder spacing */}
      <EyeSlashIcon className="h-4 w-4" aria-hidden="true" style={{ visibility: 'hidden' }} />
    </>
  );
};

export default Icons;