import { ArrowUturnLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import moment, { Moment } from 'moment/moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import styled from 'styled-components';
import { useGetLeadersQuery } from './hooks/useGetLeadersQuery';
import { checkWasApostle } from './hooks/usePresentPositionCallDate';
import { Gender, Leader } from './types';

const DateLeaderContainer = styled.div`
  .react-datepicker-wrapper {
    width: 50%;
  }
`;

type LeaderOption = { label: string, value: string } & Leader;

interface Props {
  date?: Moment,
  setDate: (value: Date | null) => void,
  setGender: (value: Gender | null) => void,
}

const DateFilter = ({ date, setDate, setGender }: Props) => {
  const [showLeaderSelector, setShowLeaderSelector] = useState(false);
  const [leaderOption, setLeaderOption] = useState<LeaderOption | null>(null);

  const toggleLeaderSelector = useCallback(() => {
    setShowLeaderSelector(!showLeaderSelector);
  }, [
    showLeaderSelector
  ]);

  const { data } = useGetLeadersQuery();

  const leaderOptions: LeaderOption[] = useMemo(() => {
    return (data?.data || []).map(leader => ({
      label: leader.name,
      value: leader.callDate,
      ...leader,
    })).sort((a, b) => a.label.localeCompare(b.label));
  }, [data]);

  const onLeaderChange = (leaderOption: OnChangeValue<LeaderOption, false>) => {
    setLeaderOption(leaderOption);
    setDate(leaderOption?.value ? moment(leaderOption.value).toDate() : null);
    if (leaderOption && !checkWasApostle(leaderOption)) {
      setGender(null); // show all genders
    }
  };

  useEffect(() => {
    if (!moment(leaderOption?.value).isSame(date)) {
      setLeaderOption(null);
    }
  }, [leaderOption, date]);
  return (
    <div className="overflow-x-clip">
      <DateLeaderContainer
        className={`relative transition ease-in-out [width:200%]${showLeaderSelector ? ` [transform:translateX(-50%)]` : ''}`}>
        <div className="flex">
          <div className="flex justify-between inline-block w-1/2">
            <label className="text-gray-700 text-sm font-bold mb-2">
              Date
            </label>
            <button className="text-xs" onClick={toggleLeaderSelector}>Select Leader <ChevronRightIcon
              className="h-3 w-3 inline" aria-hidden="true" /></button>
          </div>
          <div className="flex justify-between inline-block w-1/2">
            <div className="mb-2">
              <button onClick={toggleLeaderSelector}>
                <ArrowUturnLeftIcon className="h-3 w-3 mr-2 inline" />
              </button>
              <label className="text-gray-700 text-sm font-bold">
                Leader
              </label>
            </div>
          </div>
        </div>
        <div className="flex">
          <DatePicker
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            isClearable
            selected={date?.toDate()}
            onChange={setDate}
          />
          <Select<LeaderOption>
            name="leader"
            classNames={{
              container: () => 'w-1/2 shadow',
              placeholder: () => 'text-sm',
            }}
            onChange={onLeaderChange}
            options={leaderOptions}
            value={leaderOption}
            menuPortalTarget={document.body} // keeps dropdown on top of everything
          />
        </div>
      </DateLeaderContainer>
    </div>
  );
};

export default DateFilter;
