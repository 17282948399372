import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { debounce } from 'lodash';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { pruneParameters } from '../../utils/api';
import Layout from '../Layout';
import { useGetTalkStatsQuery } from './hooks/useGetTalkStatsQuery';
import { Row } from './types';

const Stats = () => {
    const { search } = useLocation();

    const [searchParams, setSearchParams] = useSearchParams({
      terms: '',
      sort: 'year',
      desc: '',
    });

    const params = pruneParameters(search, ['terms', 'sort', 'desc']);

    const sort = searchParams.get('sort');

    const setSort = (nextSort: string) => {
      const params = new URLSearchParams(search);
      params.set('sort', nextSort);
      if (!sort || (sort === nextSort && searchParams.get('desc') !== 'true')) {
        params.set('desc', 'true');
      } else {
        params.delete('desc');
      }
      setSearchParams(params);
    };

    const updateTermInUrl = useMemo(() => debounce((terms: string[]) => {
      setSearchParams({ 'terms[]': terms });
    }, 500), [setSearchParams]);

    const [terms, setTerms] = useState(params.terms || [
      'Russell M. Nelson',
      'Thomas S. Monson',
      'Gordon B. Hinckley',
      'Howard W. Hunter',
      'Ezra Taft Benson',
      'Spencer W. Kimball',
      'Harold B. Lee',
      'Joseph Fielding Smith',
    ]);

    const handleChangeTerms = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      updateTermInUrl(value.split(',').filter(term => !!term));
      setTerms(value);
    };

    const {
      isLoading,
      error,
      data
    } = useGetTalkStatsQuery();

    let body = undefined;

    let rows: Row[] = [];

    if (data) {
      rows = data.data;

      body = (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
          <tr>
            <th scope="col" className="px-6 py-3 align-bottom" onClick={() => setSort('year')}>
              <div className="flex">
                Year
                {(searchParams.get('desc') === 'true' && searchParams.get('sort') === 'year')
                  ? <ChevronUpIcon className="h-4 w-4" aria-hidden="true" />
                  : <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />}
              </div>
            </th>
            <th scope="col" className="px-6 py-3 align-bottom">Total</th>
            {Object.keys(rows[0].terms).map((term, i) => (
              <th
                key={i}
                scope="col"
                className="px-6 py-3 align-bottom"
                title={term}
              >
                <div
                  className="max-h-24 overflow-ellipsis overflow-hidden whitespace-nowrap rotate-180 [writing-mode:vertical-rl]">{term}</div>
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {rows.map((row, i) => (
            <tr key={i} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {row.year}
              </th>
              <td className="px-6 py-4">{row.total}</td>
              {Object.entries(row.terms).map(([key, value]) => (
                <td key={key} className="px-6 py-4">{
                  value ? <Link to={`/talks?year=${row.year}&term=${encodeURIComponent(key)}`}>{value}</Link> : '-'
                }</td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      );
    }

    if (error || !data) {
      body = <div>There was an error.</div>;
    }

    if (isLoading) {
      body = (
        <div className="flex items-center justify-center">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
          <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >
            Loading...
          </span>
          </div>
        </div>
      );
    }

    return (
      <Layout>
        <div className="container mx-auto overflow-x-scroll">
          <div className="mb-4">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              onChange={handleChangeTerms}
              value={terms ? (Array.isArray(terms) ? terms.join(',') : terms) : ''}
            />
          </div>
          {body}
        </div>
      </Layout>
    );
  }
;

export default Stats;
