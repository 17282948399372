import React, { createRef, ReactNode, useCallback, useEffect, useState } from 'react';

interface Props {
  children: ReactNode,
  caption: ReactNode
}

const Tooltip = ({
  children,
  caption
}: Props) => {
  const [show, setShow] = useState(false);

  const ref = createRef<HTMLDivElement>();

  const hide = useCallback((e: MouseEvent) => {
    if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
      setShow(false);
    }
  }, [ref]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.addEventListener('click', hide);
    }
    return () => {
      if (body) {
        body.removeEventListener('click', hide);
      }
    };
  }, [hide]);

  return (
    <div className="group inline-flex relative" onClick={() => setShow(!show)} ref={ref}>
      {children}
      <span
        className={[
          'transition-opacity',
          'bg-gray-800',
          'px-1',
          'text-sm',
          'text-gray-100',
          'rounded-md',
          'absolute',
          'whitespace-nowrap',
          'top-full',
          'mt-3',
          'text-center',
          'group-hover:opacity-100',
          'group-hover:visible',
          'group-hover:z-10',
          !show ? 'opacity-0 invisible' : '',
          show ? 'opacity-100 visible' : '',
        ].join(' ')}>
      <span
        className={[
          'absolute',
          '-top-2',
          'border-b-8',
          'border-l-8',
          'border-r-8',
          'border-b-gray-800',
          'border-l-transparent',
          'border-r-transparent',
          'h-0',
          'w-0'
        ].join(' ')} />
        {caption}
    </span>
    </div>
  );
};

export default Tooltip;
