import React from 'react';
import { RouteObject } from 'react-router';
import Layout from './components/Layout';
import Leaders from './components/Leaders';
import NotFound from './components/NotFound';
import Stats from './components/Stats';
import Talks from './components/Talks';

const Home = () => {
  return (
    <Layout>
      <div />
    </Layout>
  );
};

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/leaders',
    element: <Leaders />,
  },
  {
    path: '/stats',
    element: <Stats />,
  },
  {
    path: '/talks',
    element: <Talks />,
  },
  {
    path: '/*',
    element: <NotFound />,
  },
];