import axios from 'axios';
import { useQuery } from 'react-query';
import { pruneParameters } from '../../../utils/api';
import { env } from '../../../utils/utils';
import { ApiQuery, GetLeadersResponse } from '../types';

export const useGetLeadersQuery = (search: string = '') => useQuery<GetLeadersResponse>(
  `${ApiQuery.LEADER_QUERY}${search}`,
  async () => {
    const response = await axios.get(`${env('REACT_APP_API_URL')}/leaders`, {
      params: pruneParameters(search, ['date', 'customFilter', 'calledBy', 'showAll', 'gender']),
    });
    return response.data;
  }, {}
);
