type EnvVariable = 'REACT_APP_API_URL';

export const env = (key: EnvVariable) => {
  const value = process.env[key];
  if (value === 'false') {
    return false;
  }
  if (value === 'true') {
    return true;
  }
  return value;
};
