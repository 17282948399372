import pickBy from 'lodash/pickBy';
import queryString from 'query-string';

export const pruneParameters = (search: string, allowed: string[]) => {
  const parsed = queryString.parse(search, {
    arrayFormat: decodeURI(search).includes('[]') ? 'bracket': 'index',
  });
  const searchParams = Object.fromEntries(allowed.map(key => (
    [key, parsed[key]]
  )));
  return pickBy(searchParams, (value) => !!value) || {};
};
