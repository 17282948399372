import moment from 'moment/moment';
import { useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Gender } from '../types';

interface SearchParams {
  date: string,
  customFilter: string,
  calledBy: string,
  showAll: string,
  gender: string,
}

const useRouteChanges = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    date: '',
    customFilter: '',
    calledBy: '',
    showAll: '',
    gender: '',
  });

  const date = searchParams.get('date') ? moment(searchParams.get('date')) : undefined;
  const calledBy = searchParams.get('calledBy') || '';
  const showAll = searchParams.get('showAll') || '';
  const gender = searchParams.get('gender') ? searchParams.get('gender') as Gender : null;

  const lastSearchParamsRef = useRef<SearchParams>({
    date: searchParams.get('date') || '',
    calledBy: searchParams.get('calledBy') || '',
    showAll: searchParams.get('showAll') || '',
    gender: searchParams.get('gender') || '',
    customFilter: searchParams.get('customFilter') || '',
  });

  const partialUpdateSearchParams = useCallback((nextSearchParams: Partial<SearchParams>) => {
    lastSearchParamsRef.current = {
      ...lastSearchParamsRef.current, // use ref so this can be called quickly and not miss data
      ...nextSearchParams,
    };
    setSearchParams(Object.fromEntries(
      Object.entries(lastSearchParamsRef.current).filter(([_, value]) => !!value)
    ));
  }, [setSearchParams]);

  const setDate = useCallback((value: Date | null) => {
    partialUpdateSearchParams({
      date: value ? moment(value).format('YYYY-MM-DD') : '',
      calledBy: '',
    });
  }, [partialUpdateSearchParams]);

  const setGender = useCallback((value: Gender | null) => {
    partialUpdateSearchParams({
      gender: value || '',
      ...(value !== Gender.MALE ? { showAll: 'true' } : {}),
    });
  }, [partialUpdateSearchParams]);

  const setCalledBy = useCallback((calledBy: string) => {
    partialUpdateSearchParams({ calledBy });
  }, [partialUpdateSearchParams]);

  const toggleShowAll = useCallback(() => {
    partialUpdateSearchParams({ showAll: showAll === 'true' ? '' : 'true' });
  }, [partialUpdateSearchParams, showAll]);

  return {
    calledBy,
    date,
    gender,
    partialUpdateSearchParams,
    searchParams,
    setCalledBy,
    setDate,
    setGender,
    showAll,
    toggleShowAll,
  };
};

export default useRouteChanges;
