import moment, { Moment } from 'moment';
import { APOSTLE, PRESIDENT } from '../constants';
import { Callable, Leader } from '../types';

export const checkWasApostle = (leader: Leader) => {
  return !!leader.positions.find(position => position.type === APOSTLE || position.type === PRESIDENT);
};

export const isCalledForDate = (callable: Callable, date: Moment) => moment(callable.callDate).isSameOrBefore(date)
  && (!callable.releaseDate || moment(callable.releaseDate).isSameOrAfter(date));

export const usePresentPositionCallDate = (leader: Leader, date?: Moment) => {
  if (date) {
    const presentPosition = leader.positions.find(position => {
      return position.type === leader.presentPosition && isCalledForDate(position, date);
    });
    if (presentPosition) {
      return presentPosition.callDate;
    }
  }
  return undefined;
};
