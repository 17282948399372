import moment from 'moment';

export const CHURCH_FOUNDING_DATE = '1830-04-06';

export const EARLIEST_DATE_CALLED = moment(CHURCH_FOUNDING_DATE);

export const PRESIDENT = 'PRESIDENT';
export const FIRST_COUNSELOR = 'FIRST_COUNSELOR';
export const SECOND_COUNSELOR = 'SECOND_COUNSELOR';
export const APOSTLE = 'APOSTLE';
export const QUORUM_OF_THE_TWELVE = 'QUORUM_OF_THE_TWELVE';

const ASSISTANT_PRESIDENT = 'ASSISTANT_PRESIDENT';
const SECOND_ELDER_OF_THE_CHURCH = 'SECOND_ELDER_OF_THE_CHURCH';
const THIRD_COUNSELOR = 'THIRD_COUNSELOR';
const COUNSELOR = 'COUNSELOR';
const QUORUM_PRESIDENT = 'QUORUM_PRESIDENT';
const ACTING_QUORUM_PRESIDENT = 'ACTING_QUORUM_PRESIDENT';
const PRESIDENT_OF_ZION = 'PRESIDENT_OF_ZION';

const APOSTLE_QUORUM_COUNSELOR = 'APOSTLE_QUORUM_COUNSELOR';
const APOSTLE_QUORUM_ASSISTANT = 'APOSTLE_QUORUM_ASSISTANT';
const APOSTLE_QUORUM_ASSOCIATE = 'APOSTLE_QUORUM_ASSOCIATE';
const SEVENTY_FIRST_SEVEN_PRESIDENT = 'SEVENTY_FIRST_SEVEN_PRESIDENT';
const SEVENTY_FIRST_QUORUM_PRESIDENCY = 'SEVENTY_FIRST_QUORUM_PRESIDENCY';
const PRESIDENT_HIGH_COUNCIL = 'PRESIDENT_HIGH_COUNCIL';
const SEVENTY_PRESIDENCY = 'SEVENTY_PRESIDENCY';
const SEVENTY_FIRST_COUNCIL = 'SEVENTY_FIRST_COUNCIL';
const SEVENTY_FIRST_QUORUM = 'SEVENTY_FIRST_QUORUM';
const SEVENTY_SECOND_QUORUM = 'SEVENTY_SECOND_QUORUM';
const PRESIDING_BISHOP = 'PRESIDING_BISHOP';
const PRESIDING_BISHOP_FIRST_COUNSELOR = 'PRESIDING_BISHOP_FIRST_COUNSELOR';
const PRESIDING_BISHOP_SECOND_COUNSELOR = 'PRESIDING_BISHOP_SECOND_COUNSELOR';
const PATRIARCH = 'PATRIARCH';
const ACTING_PATRIARCH = 'ACTING_PATRIARCH';

export const RELIEF_SOCIETY_PRESIDENT = 'RELIEF_SOCIETY_PRESIDENT';
export const RELIEF_SOCIETY_FIRST_COUNSELOR = 'RELIEF_SOCIETY_FIRST_COUNSELOR';
export const RELIEF_SOCIETY_SECOND_COUNSELOR = 'RELIEF_SOCIETY_SECOND_COUNSELOR';
export const RELIEF_SOCIETY_SECRETARY = 'RELIEF_SOCIETY_SECRETARY';
const RELIEF_SOCIETY_TREASURER = 'RELIEF_SOCIETY_TREASURER';

export const VALID_LEADER_POSITIONS = [
  ASSISTANT_PRESIDENT,
  SECOND_ELDER_OF_THE_CHURCH,
  PRESIDENT_OF_ZION,
  FIRST_COUNSELOR,
  SECOND_COUNSELOR,
  SECOND_COUNSELOR,
  THIRD_COUNSELOR,
  COUNSELOR,
  QUORUM_PRESIDENT,
  ACTING_QUORUM_PRESIDENT,
  QUORUM_OF_THE_TWELVE,
  APOSTLE,
  PRESIDENT,
];

export const VALID_POSITIONS = [
  ...VALID_LEADER_POSITIONS,
  APOSTLE_QUORUM_COUNSELOR,
  APOSTLE_QUORUM_ASSISTANT,
  APOSTLE_QUORUM_ASSOCIATE,
  SEVENTY_FIRST_SEVEN_PRESIDENT,
  SEVENTY_FIRST_QUORUM_PRESIDENCY,
  PRESIDENT_HIGH_COUNCIL,
  SEVENTY_PRESIDENCY,
  SEVENTY_FIRST_COUNCIL,
  SEVENTY_FIRST_QUORUM,
  SEVENTY_SECOND_QUORUM,
  PRESIDING_BISHOP,
  PRESIDING_BISHOP_FIRST_COUNSELOR,
  PRESIDING_BISHOP_SECOND_COUNSELOR,
  PATRIARCH,
  ACTING_PATRIARCH,
  RELIEF_SOCIETY_PRESIDENT,
  RELIEF_SOCIETY_FIRST_COUNSELOR,
  RELIEF_SOCIETY_SECOND_COUNSELOR,
  RELIEF_SOCIETY_SECRETARY,
  RELIEF_SOCIETY_TREASURER,
];