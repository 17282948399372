import moment from 'moment';
import React, { ReactNode } from 'react';
import { EARLIEST_DATE_CALLED } from './constants';
import { Callable } from './types';

interface Props {
  title?: ReactNode,
  property: string,
  callable: Callable,
  setDate: (date: Date) => void,
}

const SelectableDate = ({
  title,
  property,
  callable,
  setDate,
}: Props) => {
  if (!callable.hasOwnProperty(property)) {
    return null;
  }
  const date: string = (callable as any)[property];
  const selectableDate = moment(date);
  return (
    <span>
      {title && <>{title}:&nbsp;</>}
      {callable.estimated?.includes(property) ? '≈' : ''}
      {selectableDate.isSameOrAfter(EARLIEST_DATE_CALLED) ? <a
        href={`/leaders?date=${date}`}
        onClick={(e) => {
          e.preventDefault();
          setDate(selectableDate.toDate());
        }} className="hover:underline"
      >
        {selectableDate.format('MMMM D, YYYY')}
      </a> : selectableDate.format('MMMM D, YYYY')}
    </span>
  );
};

export default SelectableDate;
