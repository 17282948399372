import axios from 'axios';
import { useQuery } from 'react-query';
import { pruneParameters } from '../../../utils/api';
import { env } from '../../../utils/utils';
import { GetTalksResponse } from '../types';

export const useGetTalkStatsQuery = (search: string) => {
  const params = pruneParameters(search, ['year', 'month', 'term']);
  return useQuery<GetTalksResponse>(
    `GET_TALKS_${search}`,
    async () => {
      const response = await axios.get(`${env('REACT_APP_API_URL')}/talks`, {
        params,
      });
      return response.data;
    }, {}
  );
};