import axios from 'axios';
import { useQuery } from 'react-query';

import { useLocation } from 'react-router-dom';
import { pruneParameters } from '../../../utils/api';
import { env } from '../../../utils/utils';
import { GetStatsTalksResponse } from '../types';

export const useGetTalkStatsQuery = () => {
  const { search } = useLocation();
  const params = pruneParameters(search, ['terms', 'sort', 'desc']);
  return useQuery<GetStatsTalksResponse>(
    `GET_TALK_STATS_${search}`,
    async () => {
      const response = await axios.get(`${env('REACT_APP_API_URL')}/stats/talks`, {
        params,
      });
      return response.data;
    }, {}
  );
};