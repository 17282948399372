import moment, { Moment } from 'moment';
import { APOSTLE, VALID_POSITIONS } from '../constants';
import { Callable, Leader } from '../types';

export enum CallingColor {
  GRAY = 'GRAY',
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
}

export const useCallingColor = (leader: Leader, date?: Moment, presentPositionCallDate?: string): CallingColor => {
  const isReleaseDate = (callable: Callable) => date && callable.releaseDate && date.isSame(callable.releaseDate);
  const isCallDate = (callable: Callable) => date && date.isSame(callable.callDate);

  if (!date) {
    return CallingColor.GRAY;
  }
  if (isReleaseDate(leader)) {
    return CallingColor.RED;
  }
  if (date.isSame(leader.callDate) && leader.positions.find(position => position.type === APOSTLE)) {
    return CallingColor.GREEN;
  }
  if ((presentPositionCallDate && date.isSame(moment(presentPositionCallDate))) ||
    leader.positions.filter(
      position => VALID_POSITIONS.includes(position.type)
    ).some(position => isCallDate(position) || isReleaseDate(position))
  ) {
    return CallingColor.YELLOW;
  }
  return CallingColor.GRAY;
};

export const getCallingBackground = (color: CallingColor) => {
  switch (color) {
    case CallingColor.RED:
      return 'bg-red-400';
    case CallingColor.GREEN:
      return 'bg-emerald-300';
    case CallingColor.YELLOW:
      return 'bg-yellow-300';
  }
  return 'bg-gray-100';
};
