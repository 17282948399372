export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

interface RawLeader {
  name: string;
  born: string;
  died: string | undefined;
  image: string | undefined;
  url: string | undefined;
  positions: string[];
  notes: string;
}

export interface Callable {
  callDate: string;
  releaseDate: string | undefined;
  estimated: string[] | undefined;
}

interface Position extends Callable {
  type: string;
  calledBy: string | undefined;
}

export interface Leader extends Callable {
  name: string,
  birthDate: string;
  deathDate: string | undefined;
  positions: Position[];
  gender: Gender;
  image: string | undefined;
  url: string | undefined;
  age: number | undefined;
  polygamist: number | undefined;
  yearsSinceCall: number | undefined;
  presentPosition: string | undefined;
  notes: string;
  rawData: RawLeader;
}

export interface GetLeadersResponse {
  data: Leader[],
  meta: {
    averageAge: number | undefined,
    pagination: {
      prevLeaderChangeDate: string | undefined,
      nextLeaderChangeDate: string | undefined,
      prevProphetChangeDate: string | undefined,
      nextProphetChangeDate: string | undefined,
    },
    options: {
      calledBys: string[],
    }
  }
}

export enum ApiQuery {
  LEADER_QUERY = 'LEADER_QUERY',
}

export enum DetailLevel {
  SIMPLE = 'SIMPLE',
  BASIC = 'BASIC',
  FULL = 'FULL',
}
