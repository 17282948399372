import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import moment from 'moment';
import React from 'react';
import DateFilter from './DateFilter';
import useRouteChanges from './hooks/useRouteChanges';
import Tooltip from './Tooltip';
import { DetailLevel, Gender, GetLeadersResponse } from './types';

interface Props {
  detailLevel: DetailLevel,
  setDetailLevel: (detailLevel: DetailLevel) => void,
  prevLeaderChangeDate: GetLeadersResponse['meta']['pagination']['prevLeaderChangeDate'],
  nextLeaderChangeDate: GetLeadersResponse['meta']['pagination']['nextLeaderChangeDate'],
  prevProphetChangeDate: GetLeadersResponse['meta']['pagination']['prevProphetChangeDate'],
  nextProphetChangeDate: GetLeadersResponse['meta']['pagination']['nextProphetChangeDate'],
  calledBys: GetLeadersResponse['meta']['options']['calledBys'],
}

const Filters = ({
  detailLevel,
  setDetailLevel,
  prevLeaderChangeDate,
  nextLeaderChangeDate,
  prevProphetChangeDate,
  nextProphetChangeDate,
  calledBys,
}: Props) => {

  const {
    calledBy,
    date,
    gender,
    setCalledBy,
    setDate,
    setGender,
    showAll,
    toggleShowAll,
  } = useRouteChanges();

  return (
    <>
      <div>
        <div className="mb-4">
          <DateFilter setDate={setDate} setGender={setGender} date={date} />
        </div>
        {date && <>
          <div className="flex flex-col md:flex-row">
            <button
              className={[
                'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center',
                !prevProphetChangeDate || gender === Gender.FEMALE ? 'opacity-50 cursor-not-allowed' : '',
              ].join(' ')}
              onClick={() => setDate(moment(prevProphetChangeDate).toDate())}
              disabled={!prevProphetChangeDate || gender === Gender.FEMALE}
            >
              <ChevronDoubleLeftIcon className="h-6 w-6" aria-hidden="true" />
              <span>Last Prophet</span>
            </button>
            <button
              className={[
                'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center',
                !prevLeaderChangeDate ? 'opacity-50 cursor-not-allowed' : '',
              ].join(' ')}
              onClick={() => setDate(moment(prevLeaderChangeDate).toDate())}
              disabled={!prevLeaderChangeDate}
            >
              <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
              <span>Last Leader</span>
            </button>
            <button
              className={[
                'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center',
                !nextLeaderChangeDate ? 'opacity-50 cursor-not-allowed' : '',
              ].join(' ')}
              onClick={() => setDate(moment(nextLeaderChangeDate).toDate())}
              disabled={!nextLeaderChangeDate}
            >
              <span>Next Leader</span>
              <ChevronRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              className={[
                'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center',
                !nextProphetChangeDate || gender === Gender.FEMALE ? 'opacity-50 cursor-not-allowed' : '',
              ].join(' ')}
              onClick={() => setDate(moment(nextProphetChangeDate).toDate())}
              disabled={!nextProphetChangeDate || gender === Gender.FEMALE}
            >
              <span>Next Prophet</span>
              <ChevronDoubleRightIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </>}
      </div>
      <div className="my-2">
        <label>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="calledBys">
            Called By
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="calledBys"
            onChange={(e) => setCalledBy(e.target.value)}
            value={calledBy}
          >
            <option value="">--</option>
            {calledBys.map(calledBy => <option key={calledBy} value={calledBy}>{calledBy}</option>)}
          </select>
        </label>
      </div>
      <div className="my-2">
        <label>
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
            Gender
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="gender"
            onChange={(e) => {
              setGender(
                (e.target.value === Gender.FEMALE || e.target.value === Gender.MALE)
                  ? e.target.value
                  : null
              );
            }}
            value={gender || ''}
          >
            <option value="">--</option>
            {Object.values(Gender).map(genderOption => (
              <option
                key={genderOption}
                value={genderOption}
              >
                {genderOption}
              </option>
            ))}
          </select>
        </label>
      </div>
      <div className="my-2">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          <input
            type="checkbox"
            className="h-4 w-4 rounded-full shadow mr-2"
            onChange={toggleShowAll}
            checked={!!showAll}
          />
          <Tooltip caption="Includes outside first presidency and apostles">
            Show All Leaders
          </Tooltip>
        </label>
      </div>
      <div className="my-2">
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Detail Level
        </label>
        <div className="flex">
          <label className="block text-gray-700 text-sm flex items-center mr-2">
            <input
              type="radio"
              className="h-4 w-4 rounded-full mr-2"
              onChange={() => setDetailLevel(DetailLevel.SIMPLE)}
              checked={DetailLevel.SIMPLE === detailLevel}
              value={DetailLevel.SIMPLE}
            />
            <Tooltip caption="Show name and icons">
              Simple
            </Tooltip>
          </label>
          <label className="block text-gray-700 text-sm flex items-center mr-2">
            <input
              type="radio"
              className="h-4 w-4 rounded-full mr-2"
              onChange={() => setDetailLevel(DetailLevel.BASIC)}
              checked={DetailLevel.BASIC === detailLevel}
              value={DetailLevel.BASIC}
            />
            <Tooltip caption="Show name, positions, and icons">
              Basic
            </Tooltip>
          </label>
          <label className="block text-gray-700 text-sm flex items-center mr-2">
            <input
              type="radio"
              className="h-4 w-4 rounded-full mr-2"
              onChange={() => setDetailLevel(DetailLevel.FULL)}
              checked={DetailLevel.FULL === detailLevel}
              value={DetailLevel.FULL}
            />
            <Tooltip caption="Show all positions and notes">
              Full
            </Tooltip>
          </label>
        </div>
      </div>
    </>
  );
};

export default Filters;
