import React, { useEffect, useState } from 'react';
import Layout from '../Layout';
import { CHURCH_FOUNDING_DATE } from './constants';
import Filters from './Filters';
import { useGetLeadersQuery } from './hooks/useGetLeadersQuery';
import useRouteChanges from './hooks/useRouteChanges';
import LeaderCard from './LeaderCard';
import { DetailLevel, Gender } from './types';

const Leaders = () => {
  const { searchParams, partialUpdateSearchParams, date, gender } = useRouteChanges();

  // set defaults filters when nothing is set
  useEffect(() => {
    if (!date && !gender) {
      partialUpdateSearchParams({ date: CHURCH_FOUNDING_DATE, gender: Gender.MALE });
    }
    // eslint-disable-next-line
  }, []);

  const {
    isLoading,
    error,
    data
  } = useGetLeadersQuery(searchParams.toString());

  const [detailLevel, setDetailLevel] = useState(DetailLevel.BASIC);

  let body = undefined;

  let leaders = [];
  let calledBys: string[] = [];
  let prevLeaderChangeDate = undefined;
  let nextLeaderChangeDate = undefined;
  let prevProphetChangeDate = undefined;
  let nextProphetChangeDate = undefined;
  let averageAge = undefined;

  if (data) {
    leaders = data.data;
    calledBys = data.meta.options.calledBys;
    prevLeaderChangeDate = data.meta.pagination.prevLeaderChangeDate;
    nextLeaderChangeDate = data.meta.pagination.nextLeaderChangeDate;
    prevProphetChangeDate = data.meta.pagination.prevProphetChangeDate;
    nextProphetChangeDate = data.meta.pagination.nextProphetChangeDate;
    averageAge = data.meta.averageAge;

    body = (
      <>
        <div className="flex flex-wrap -mx-1 lg:-mx-4">
          {leaders.map((leader, i) => (
            <div key={i} className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
              <LeaderCard leader={leader} detailLevel={detailLevel} />
            </div>
          ))}
        </div>
      </>
    );
  }

  if (error || !data) {
    body = <div>There was an error.</div>;
  }

  if (isLoading) {
    body = (
      <div className="flex items-center justify-center">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
          <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >
            Loading...
          </span>
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto">
        <Filters
          detailLevel={detailLevel}
          setDetailLevel={setDetailLevel}
          prevLeaderChangeDate={prevLeaderChangeDate}
          nextLeaderChangeDate={nextLeaderChangeDate}
          prevProphetChangeDate={prevProphetChangeDate}
          nextProphetChangeDate={nextProphetChangeDate}
          calledBys={calledBys}
        />
        {averageAge && <div>Average Age: {averageAge}</div>}
        {body}
      </div>
    </Layout>
  );
};

export default Leaders;
