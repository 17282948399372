import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../Layout';
import { useGetTalkStatsQuery } from './hooks/useGetTalkStatsQuery';
import { Talk } from './types';

const Result = ({ talk, term }: { talk: Talk, term: string }) => {
  const result = talk.text.split('\n').find(a => term && a.includes(term)) || '';
  const texts = result.split(term);
  return (
    <p className="text-sm pt-1">{texts.map((text, i) => (
      <Fragment key={i}>
        <span>{text}</span>
        {texts.length - 1 !== i && <span className="bg-yellow-300">{term}</span>}
      </Fragment>
    ))}</p>
  );
};

const getMonth = (month: number) => {
  const date = new Date(1970, month - 1, 1);
  return date.toLocaleString('default', { month: 'long' });
};

const Stats = () => {
    const { search } = useLocation();

    const searchParams = new URLSearchParams(search);
    const term = searchParams.get('term');

    const {
      isLoading,
      error,
      data
    } = useGetTalkStatsQuery(search);

    let body = undefined;

    let talks: Talk[] = [];

    if (data) {
      talks = data.data;

      body = talks.map(talk => (
        <div key={talk.id} className="pb-4">
          <a href={talk.url} target="_blank" rel="noreferrer" className="font-bold">{talk.title}</a>
          <p className="text-sm text-gray-800">{talk.year} {getMonth(talk.month)} – {talk.speaker}</p>
          {term && <Result talk={talk} term={term} />}
        </div>
      ));
    }

    if (error || !data) {
      body = <div>There was an error.</div>;
    }

    if (isLoading) {
      body = (
        <div className="flex items-center justify-center">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
          <span
            className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
          >
            Loading...
          </span>
          </div>
        </div>
      );
    }

    return (
      <Layout>
        <div className="container mx-auto">
          {body}
        </div>
      </Layout>
    );
  }
;

export default Stats;
