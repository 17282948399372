import React from 'react';
import Layout from '../Layout';

const NotFound = () => (
  <Layout>
    <div className="container mx-auto">
      Not found
    </div>
  </Layout>
);

export default NotFound;
