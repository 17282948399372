import React from 'react';
import { APOSTLE, QUORUM_OF_THE_TWELVE, VALID_POSITIONS } from './constants';
import { getCallingBackground, useCallingColor } from './hooks/useCallingColor';
import { isCalledForDate, usePresentPositionCallDate } from './hooks/usePresentPositionCallDate';
import useRouteChanges from './hooks/useRouteChanges';
import Icons from './Icons';
import SelectableDate from './SelectableDate';
import { DetailLevel, Leader } from './types';

const formatPosition = (position: string) => {
  return position.replace(/_/g, ' ');
};

interface Props {
  leader: Leader,
  detailLevel: DetailLevel,
}

const LeaderCard = ({
  leader,
  detailLevel,
}: Props) => {
  const { date, setDate } = useRouteChanges();

  const presentPositionCallDate = usePresentPositionCallDate(leader, date);

  const callingColor = useCallingColor(leader, date, presentPositionCallDate);

  return (
    <article className={[
      'flex',
      'flex-col',
      'h-full',
      ' leading-none p-2 md:p-4',
      'rounded-lg shadow-lg',
      getCallingBackground(callingColor)
    ].join(' ')}>
      <div className="flex">
        {leader.image && <a href={leader.url} target="_blank" rel="noreferrer">
          <div
            className="w-12 h-12 rounded-full bg-top bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${leader.image})` }}
          />
        </a>}
        <div className="w-full">
          <div className="ml-2 text-sm text-left flex">
            <a href={leader.url} target="_blank" rel="noreferrer">
              <h1 className="text-lg hover:underline flex items-center">
                {leader.name}
              </h1>
            </a>
          </div>
          {leader.presentPosition && <p className="ml-2 text-sm">
            {(
              date && leader.presentPosition === APOSTLE
              && leader.positions.some(
                (position) => position.type === QUORUM_OF_THE_TWELVE && isCalledForDate(position, date)
              )
            ) ? 'Q12'
              : formatPosition(leader.presentPosition)
            }
          </p>}
          <div className="ml-2 flex">
            <Icons leader={leader} />
          </div>
        </div>
      </div>
      <div className="pt-2 md:pt-4 text-sm">
        {detailLevel !== DetailLevel.SIMPLE && <div className="pb-2 md:pb-4">
          <div className="text-sm flex justify-between">
            <SelectableDate title="Born" property="birthDate" callable={leader} setDate={setDate} />
            {date && <span>Age: {leader.age}</span>}
          </div>
          <div className="text-sm flex justify-between">
            {leader.deathDate ?
              <SelectableDate title="Died" property="deathDate" callable={leader} setDate={setDate} /> :
              <div>Living</div>}
            {date && leader.yearsSinceCall !== undefined && leader.yearsSinceCall >= 0 && <>
              Years Since Call: {leader.yearsSinceCall}
            </>}
          </div>
        </div>}
        {detailLevel === DetailLevel.SIMPLE && leader.positions.filter(position => position.type === leader.presentPosition).map((position, i) => (
          <div className="ml-2 flex" key={i}>
            <SelectableDate property="callDate" callable={position} setDate={setDate} />
            &nbsp;–&nbsp;
            {position.releaseDate ?
              <SelectableDate
                property="releaseDate"
                callable={position}
                setDate={setDate}
              /> : 'Present'}
          </div>
        ))}
        {detailLevel !== DetailLevel.SIMPLE && leader.positions.filter(position => VALID_POSITIONS.includes(position.type)).map((position, i) => (
          <div
            key={i}
            className={[
              'pt-2',
              date && !isCalledForDate(position, date) && detailLevel !== DetailLevel.FULL && 'hidden',
              date && !isCalledForDate(position, date) ? 'opacity-50' : '',
            ].join(' ')}
          >
            <span>{formatPosition(position.type)}</span>
            <div className="ml-2 flex">
              <SelectableDate property="callDate" callable={position} setDate={setDate} />
              &nbsp;–&nbsp;
              {position.releaseDate ?
                <SelectableDate
                  property="releaseDate"
                  callable={position}
                  setDate={setDate}
                /> : 'Present'}
            </div>
          </div>
        ))}
      </div>
      {detailLevel === DetailLevel.FULL && leader.notes && <div className="pt-2 md:pt-4 text-sm">
        {leader.notes}
      </div>}
    </article>
  );
};

export default LeaderCard;
